import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash-es/get';
import styled from 'styled-components';

import RegistrationStepPageBody from '../Community/Registration/RegistrationStepPageBody';
import PageBody from '../PageBody';
import { isNullValue } from '../../utils/functions';

const PortalBodyWrapper = styled.div`
  padding: 0 24px;
`;

const propTypes = { data: PropTypes.object, template: PropTypes.string };

const defaultProps = { data: {}, template: '' };

function EmbeddedDocument({ data }) {
  const document = get(data, 'primary.document_link.document');

  if (!document) {
    return null;
  }

  if (document.type === 'registration_step') {
    return (
      <RegistrationStepPageBody
        title={document.data.step_title}
        shouldDisplayTitle={document.data.display_title}
        shouldDisplayStepCounter={false}
        body={document.data.body}
      />
    );
  } else if (document.type === 'community_portal_page') {
    // When embedding a portal page, there are some slices that should not appear for unauthenticated users. So we need to filter the body here.
    const unauthenticatedDisplayModes = [
      'unauthenticated_only',
      'authenticated_and_unauthenticated'
    ];
    const filteredBody = (document.data.body || []).filter((bodySection) => {
      const displayMode = get(bodySection, 'primary.display_mode', null);

      // if display_mode is null/undefined, that means the component should show up everywhere, since we did not add a display_mode field in prismic
      return (
        isNullValue(displayMode) ||
        unauthenticatedDisplayModes.includes(displayMode)
      );
    });

    return (
      <PortalBodyWrapper>
        <PageBody body={filteredBody} pageUid={document.uid} />;
      </PortalBodyWrapper>
    );
  } else {
    return null;
  }
}

EmbeddedDocument.propTypes = propTypes;
EmbeddedDocument.defaultProps = defaultProps;

export default EmbeddedDocument;
